/**
 * 轮播图信息表模块接口列表
 */
import request from '@/api/myaxios'; // 导入http中创建的axios实例

const rotation = {
    // 首页，点击轮播图
    click(id){
        return request({
            url:'/api/info/rotation/click/' + id,
            method:'get'
        })
    },
    // 轮播图信息表查询
    queryRotation(data) {
        return request({
            url: '/api/info/rotation/queryRotation',
            method: 'post',
            data: data
        });
    },
    // 删除轮播图信息表
    delRotation(id) {
        return request({
            url: '/api/info/rotation/delRotation/'+id,
            method: 'get'
        });
    },
    // 新增轮播图信息表
    addRotation(data) {
        return request({
            url: '/api/info/rotation/addRotation',
            method: 'post',
            data: data
        });
    },
    // 编辑轮播图信息表
    editRotation(data) {
        return request({
            url: '/api/info/rotation/editRotation',
            method: 'post',
            data: data
        });
    },
}

export default rotation;