/**
 * content模块接口列表
 */
 import request from '@/api/myaxios'; // 导入http中创建的axios实例

 const content ={
     //点击增加浏览量
     addClicksContent(contentId){
         return request({
             url:'/api/content/addClicksContent/' + contentId,
             method:'get'
         })
     },
    //行内编辑
    rowEditSeq(data){
        return request({
            url: '/api/content/rowEditSeq',
            method: 'post',
            data: data
        })
    },
    
    //根据栏目ID分页查询内容列表
     pageContentByChannelId(data){
        return request({
            url: '/api/content/pageContentByChannelId',
            method: 'post',
            data: data
        })
    },

    //根据内容id查询内容详情
    queryDetailsContent(id, channelId){
        return request({
            url: '/api/content/queryDetailsContent/'+ id +'/' + channelId,
            method: 'get'
        })
    },

     //上传
     uploadFile(data) {
        return request({
            url: '/api/content/uploadFile',
            method: 'post',
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
     
    //查询
    queryContent(data) {
        return request({
            url: '/api/content/queryContent',
            method: 'post',
            data: data
        })
    },

    //修改置顶
    changeIsTop(data){
        return request({
            url: '/api/content/changeIsTop',
            method: 'post',
            data: data
        })
    },

    // 新增
    addContent(data){
        return request({
            url:'/api/content/addContent',
            method: 'post',
            data:data
        })
    },

    // 修改
    editContent(data){
        return request({
            url:'/api/content/editContent',
            method:'post',
            data: data
        })
    },

    //上、下架
    upDownContent(id, status) {
        return request({
            url:'/api/content/upDownContent/'+ id+'/'+ status,
            method:'get',
        })
    },
    //根据id删除内容
    deleteContent(id) {
        return request({
            url:'/api/content/deleteContent/'+ id,
            method:'get',
        })
    },

 }

 export default content