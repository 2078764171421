const util = {
    // 剪切标题，处理过长标题显示
    cutTitle(title, length) {
        if (length == undefined) {
            length = 35;
        }
        if (title.length >= length) {
            return title.substring(0, length-1) + '...';
        }
        return title;
    },
    // 只保留纯文本
    removeTags(str) {
        return str.replace(/<[^>]+>/g, '');
    },
}

export default util