<template>
  <Head :data="data"/>
  <div class="roration-main">
    <van-swipe :autoplay="300000" indicator-color="#409eff" style="width: 100%;">
      <van-swipe-item v-for="(item,index) in picList0" :key="index">
        <img :src="serverURL+item.image"/>
      </van-swipe-item>
    </van-swipe>
  </div>
  <div class="title">创新不止30年·未来无限可能</div>
  <div class="sub-title">我们的存在是为了重新定义您对厨房电器的期望，创造人类对厨房生活的一切美好向往</div>
  <div class="slogan-list">
    <el-row>
      <el-col :span="6" v-for="slogan in sloganList">
        <img :src="slogan.icon"/>
        <p>{{ slogan.title }}</p>
      </el-col>
    </el-row>
  </div>

  <!-- 幻灯片2 -->
  <div class="roration-main" style="padding-top: 0">
    <van-swipe :autoplay="3000" indicator-color="#409eff" style="width: 100%;">
      <van-swipe-item v-for="(item,index) in picList1" :key="index">
        <img :src="serverURL+item.image"/>
      </van-swipe-item>
    </van-swipe>
  </div>

  <div class="title">经典厨电·雅享美好时光</div>
  <div class="sub-title">以技术创新带动迭代升级，为热爱生活方式的您推送高颜值高品质的产品</div>

  <div class="news">
    <!--    <div class="news-title">
          <span>产品中心</span>
          <a @click="viewMore(productChannelId,'产品中心')">更多&gt;&gt;</a>
        </div>-->
    <div class="img-box">
      <el-row :gutter="4">
        <el-col :span="8" class="img-item" v-for="(item,index) in productList" :key="index"
                @click="contentDetail(item.id, productChannelId, '产品中心')">
          <img v-if="!item.error" :src="serverURL+item.thumbnail" @error="item.error=true"/>
          <img v-else src="../../assets/images/front/no-pic-mbzs.png"/>
          <el-link>{{ strUtil.cutTitle(item.title, 8) }}</el-link>
        </el-col>
      </el-row>
    </div>
    <el-link :underline="false" type="primary" style="width: 100%;line-height: 40px;" @click="viewMore(productChannelId,'产品中心')">更多产品&gt;&gt;</el-link>
    <!--产品-->
    <div class="news-title"><span>常见问题</span>
      <a @click="viewMore(problemChannelId,'常见问题')">更多&gt;&gt;</a>
    </div>
    <div class="problem-list">
      <div class="problem-item" v-for="p in problemList">
        <div class="problem-title">
          <span>Q</span>{{ strUtil.cutTitle(p.title, 70) }}
        </div>
        <div class="problem-content">
          <div class="content" v-html="strUtil.cutTitle(p.content,160)"></div>
        </div>
      </div>
    </div>

    <!--友情链接-->
    <div class="news-title"><span>友情链接</span></div>
    <div class="link-list">
      <el-link type="primary" v-for="l in linkList" @click="openUrl(l.url)">{{l.name}}</el-link>
    </div>
  </div>

<div style="height: 20px;background-color: #000000"></div>
</template>

<script>
import {ref, onMounted, nextTick} from "vue";
import {useRoute, useRouter, onBeforeRouteUpdate} from 'vue-router';
import 'vant/lib/index.css';

import rotationApi from "@/api/info/rotation";
import config from '@/api/config'
import Head from '@/components/h5/Head'
import contentApi from "@/api/info/content";
import friendlinkApi from "@/api/info/friendlink";
import strUtil from "@/utils/strUtil";

export default {

  components: {
    Head
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const sloganList = [
      {icon: require('@/assets/images/front/slogan1.png'), title: '探究新可能'},
      {icon: require('@/assets/images/front/slogan2.png'), title: '忠于产品'},
      {icon: require('@/assets/images/front/slogan3.png'), title: '与用户共振'},
      {icon: require('@/assets/images/front/slogan4.png'), title: '探索未知'},
    ]
    const linkList=ref([])

    const notLogo = require('../../assets/images/front/no-pic-pc.png')
    const data = ref({
      sign: false,
      title: '首页',
      back: false
    });

    const productChannelId = ref(1);
    const problemChannelId = ref(4)

    const productList = ref([]);  //产品
    const problemList = ref([]);  //问题

    const contentSize = ref(6);  //内容条数

    const serverURL = ref('');
    //定义轮播图接收数组
    const picList0 = ref([]);
    const picList1 = ref([]);
    const picSize = ref(20)
    const queryRotation = () => {
      rotationApi.queryRotation({
        pageSize: picSize.value,
        curPage: 1,
        status: 1,
      }).then((res) => {
        let picList = res.data.data.records;
        for (let i = 0; i < picList.length; i++) {
          if (picList[i].groupName == '001') {
            picList0.value.push(picList[i]);
          } else if (picList[i].groupName == '002') {
            picList1.value.push(picList[i]);
          }
        }
      });
    };
    //播图跳转地址
    const openUrl = (url) => {
      window.open(url);
    };

    // 更多跳转
    const viewMore = (channelId, channelName) => {
      router.push({
        path: '/h5/channel',
        query: {
          channelName: channelName,
          channelId: channelId
        }
      })
    };

    //查询容列表
    const getContentByChannelId = (id, that, contentSize,isTop) => {
      contentApi.pageContentByChannelId({pageSize: contentSize, curPage: 1, channelId: id,isTop:isTop}).then((res) => {
        that.value = res.data.data.records;
      })
    };


    //文章详情
    const contentDetail = (id, channelId, channelName) => {
      router.push({
        path: '/h5/content',
        query: {
          channelId: channelId,
          channelName: channelName,
          contentId: id
        }
      })
    }

    // 获取友情链接
    const queryLink=(groupName)=> {
      friendlinkApi.queryLink({groupName:groupName}).then((res) => {
        linkList.value = res.data.data;
      })
    }


    onMounted(() => {
      serverURL.value = config.server;
      queryRotation();

      getContentByChannelId(1, productList, contentSize.value,1);
      getContentByChannelId(4, problemList, contentSize.value,'');

      // 链接
      queryLink('底部链接')
    })
    return {
      contentSize,
      notLogo,
      data,
      serverURL,
      openUrl,
      contentDetail,
      picList0,
      picList1,
      picSize,
      queryRotation,
      viewMore,
      productChannelId,
      problemChannelId,
      problemList,
      productList,
      strUtil,
      sloganList,
      linkList,
    }
  }
}
</script>

<style scoped>
.roration-main {
  padding-top: 46px;
  background-color: #000000;
}

.roration-main a {
  top: 75%;
  width: 100%;
  display: block;
  position: absolute;
  text-align: center;
  color: white;
}

.van-swipe-item {
  overflow: hidden;
}

.van-swipe-item img {
  width: 100%;
  height: 250px;
}

.news {
  background-color: #000000;
  color: #FFFFFF;
}

.news-title {
  justify-content: space-between;
  display: flex;
  margin: 10px 10px;
  font-weight: bold;
}

.news-title a {
  font-size: 14px;
  font-weight: normal;
}

.news-title a {
  color: var(--van-primary-color);
}

.van-divider {
  margin: 2px 0 0 0;
}

:deep().van-cell {
  padding: 5px 10px 5px 0;
}

:deep().van-card {
  background-color: white;
  height: 4.3rem;
}

:deep().van-card__title {
  margin-top: 3px;
  height: 30px;
}

:deep().van-card__content {
  justify-content: space-between;
  font-size: 13px;

}

:deep().van-card__thumb {
  height: 4.3rem;
  width: 6.4rem;
}

/deep/ .van-image {
  height: 90% !important;
}

.img-box {
  width: 100%;
  /*padding: 10px;*/
}

.img-box .el-row {
  padding: 5px;
}

.img-item {
  display: inline-block;
  text-align: center;
  height: 100%;
  /*width: 33.3%;*/
  /*margin: 0 10px 10px 0;*/
  /* overflow: hidden; */
  margin-bottom: 10px;
}

.img-item img {
  width: 100%;
  height: 200px;
  display: block;
}

.img-item .el-link {
  color: #FFFFFF;
  padding: 5px 0;
}

.title {
  background-color: #000000;
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
  /*line-height: 25px;*/
  padding-top: 10px;
}

.sub-title {
  background-color: #000000;
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  /*line-height: 25px;*/
  padding: 10px 5px;
}

.slogan-list {
  background-color: #000000;
  color: #FFFFFF;
  font-size: 14px;
  padding-top: 10px;
}

.slogan-list .el-row {
  margin: 0 auto;
}

.slogan-list .el-row .el-col {
  text-align: center;
}

.slogan-list .el-row .el-col img {
  width: 35px;
}
.problem-list{
  padding: 5px;
  font-size: 14px;
}
.problem-item {
  margin-bottom: 5px;
  border: #FFFFFF solid 1px;
}

.problem-title {
  line-height: 30px;
  border-bottom: #FFFFFF solid 1px;
}

.problem-title span {
  display: inline-block;
  text-align: center;
  color: #696969;
  width: 30px;
  background-color: #FFFFFF;
  margin-right: 10px;
}

.problem-content {
  /*line-height: 40px;*/
}

.problem-content .content {
  padding: 0 10px;
}

.link-list{
  padding: 5px;
  font-size: 14px;
  border-bottom: #FFFFFF solid 1px;
}
.link-list .el-link{
  padding: 5px;
}

</style>